// @flow
import React from 'react';
import { Provider } from '%/context';
import IntlProvider from '%/intlProvider';
import NotFound from '../NotFound';

type Props = {|
   pageContext: {
      locale: 'cs' | 'en',
   },
|};

const NotFoundPage = ({ pageContext }: Props) => (
   <Provider
      value={{
         project: false,
         locale: pageContext.locale,
      }}
   >
      <IntlProvider>
         <NotFound locale={pageContext.locale} />
      </IntlProvider>
   </Provider>
);

export default NotFoundPage;
