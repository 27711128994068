// @flow
import React from 'react';
import { injectIntl } from 'react-intl';
import Helmet from 'react-helmet';
// import { useStaticQuery, graphql } from 'gatsby';

type Props = {|
   title: string,
   lang: string,
|};

function SEO({ lang, title }: Props) {
   // const { site } = useStaticQuery(
   //    graphql`
   //       query {
   //          site {
   //             siteMetadata {
   //                title
   //                author
   //             }
   //          }
   //       }
   //    `,
   // );

   // const metaDescription = description || intl.messages['meta.description'];
   return (
      <Helmet
         htmlAttributes={{
            lang,
         }}
         title={title}
         meta={[]}
      >
         <link
            href='https://fonts.googleapis.com/css?family=Montserrat:400,600&amp;subset=latin-ext'
            rel='stylesheet'
         />
      </Helmet>
   );
}

export default injectIntl(SEO);
