// @flow
import React from 'react';

import styles from './styles.module.scss';

const HambEscape = () => (
   <div className={`${styles.hamb} ${styles.active} ${styles.escape}`}>
      <span className={styles.item} />
      <span className={styles.item} />
      <span className={styles.item} />
   </div>
);

export default HambEscape;
