// @flow
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';

import Logo from '#/Header/Logo/Logo';
import Escape from '#/Header/Hamb/HambEscape';
import styles from './styles.module.scss';
import SEO from './seo';

type Props = {|
   locale: 'cs' | 'en',
   intl: any,
|};

const NotFound = ({ intl, locale }: Props) => (
   <>
      <SEO
         lang={locale}
         title={
            locale === 'cs' ? '404: stránka nenalezena' : '404: Page not found'
         }
      />
      <div className={styles.wrapper}>
         <div className={`${styles.header}`}>
            <div className={`${styles.innerHeader} container`}>
               <Logo />
               <Link to={`${locale === 'cs' ? '/' : '/en'}`}>
                  <Escape />
               </Link>
            </div>
         </div>
         <div className={styles.content}>
            <h1>404</h1>
            <p>{intl.formatMessage({ id: 'notFound' })}</p>
         </div>
      </div>
   </>
);

export default injectIntl(NotFound);
